<template>
  <div class='output popUpScrollList'>
    <div class="element materialShadow">
      <div class="install_help">
        <h2>Android</h2>
        1) {{ x('installHelpAndroidStep1') }}
        <br><br>
        <img :src="`${imgPath}_installhelpandroid1.jpg`">
        <br><br>
        2) {{ x('installHelpAndroidStep2a') }}
        <br><br>
        <b>{{ config.publicProperties.CustomerLongDomain }}</b>
        <br><br>
        {{ x('installHelpAndroidStep2b') }}
        <br><br>
        <img :src="`${imgPath}_installhelpandroid2.jpg`">
        <br><br>
        3) {{ x('installHelpAndroidStep3a') }} <b>{{ config.publicProperties.AppName }}</b> {{
        x('installHelpAndroidStep3b') }}
        <br><br>
        <img :src="`${imgPath}_installhelpandroid3.jpg`">
        <br><br>
        4) {{ x('installHelpAndroidStep4a') }}
        <br><br>
        <b>{{ x('installHelpAndroidStep4b') }}</b>
        <br><br>
        <img :src="`${imgPath}_installhelpandroid4.jpg`">
        <br><br>
        <h2>IOS</h2>
        1) {{ x('installHelpIOSStep1a') }}
        <br><br>
        <b>{{ config.publicProperties.CustomerLongDomain }}</b>
        <br><br>
        {{ x('installHelpIOSStep1b') }}
        <br><br>
        <img :src="`${imgPath}_installhelpios1.jpg`">
        <br><br>
        2) {{ x('installHelpIOSStep2') }}
        <br><br>
        <img :src="`${imgPath}_installhelpios2.jpg`">
        <br><br>
        3) {{ x('installHelpIOSStep3a') }} <b>{{ config.publicProperties.AppName }}</b>
        {{ x('installHelpIOSStep3b') }}
        <br><br>
        <img :src="`${imgPath}_installhelpios3.jpg`">
        <br><br>
        4) {{ x('installHelpIOSStep4a') }}
        <br><br>
        <b>{{ x('installHelpIOSStep4b') }}</b>
        <br><br>
        <img :src="`${imgPath}_installhelpios4.jpg`">
        <br><br>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';

  export default {
    name: 'install-help',
    mixins: [translate],
    computed: {
      imgPath() {
        return `/static/assets/gfx/install_help/${this.config.publicProperties.CustomerShortDomain}`;
      },
      ...mapState(['config']),
    },
    mounted() {
      this.$emit(ComponentEventNames.didLoad);
    },
  };
</script>
